import { css } from "@emotion/core"
import { Button, Grid, Typography, useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link, graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import React from "react"
import Logo from "../assets/svgs/Logo_color.svg"
import Section from "./Section"
import Stack from "./Stack"

// const containerStyle = css`
//   //display: flex;
//   position: relative;
//   //justify-content: center;
//   text-align: center;
//   //background-color: #ccc;
//   padding-top: 128px;
// `
// const backgroundStyle = css`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: -2;
//   overflow: visible;
// `
//
// const svgStyle = css`
//   left: -50vw;
//   transform-origin: center center;
//   will-change: transform;
//   width: calc(90vw + 25vh);
//   height: auto;
//   position: absolute;
//   overflow: hidden;
//   //animation: 100s cubic-bezier(0.4, 0, 0.2, 1) 2s infinite normal none running animation-yht4ov;
// `

const sectionStyle = css`
  margin-bottom: 0;
`
//
// const leftBlockStyle = css`
//   max-width: 560px;
// `

const Hero = ({}) => {
  const theme = useTheme()
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"))

  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"))
  // const showFullImage = !useMediaQuery(theme.breakpoints.between("md", "lg"))

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "HeroImage-Collage-Linux-Windows.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      partImage: file(relativePath: { eq: "HeroImage-Collage-Part-Linux-Windows.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const sources = [
    data.image.childImageSharp.fluid,
    {
      ...data.partImage.childImageSharp.fluid,
      media: `(min-width:960px) and (max-width:1279px)`,
    },
  ]

  const heroImageStyle = css`
    ${matchUpMd
      ? ""
      : "div {padding-bottom: 0 !important; }" +
        "img {position: relative !important;}"}
  `

  return (
    <Section css={sectionStyle}>
      {/*<div css={backgroundStyle}>*/}
      {/*  <Background css={svgStyle}/>*/}

      {/*</div>*/}
      <Grid container justify="center" alignItems={"center"}>
        <Grid item xs={false} md={1} xl={2} />
        <Grid item xs={12} md={5} xl={3}>
          <Stack
            align={matchUpMd ? "left" : "center"}
            space={64}
            css={css`
              padding: 24px;
            `}
          >
            <Logo
              css={css`
                width: 256px;
              `}
            />
            <Typography
              variant={"h2"}
              css={css`
                font-weight: 600;
              `}
              component={"p"}
            >
              Build cross-platform apps that rock! With Java and Kotlin.
            </Typography>
            <Typography
              variant={"subtitle2"}
              component={"p"}
            >
              MobileUI was the first Java-based native UI framework for iOS and
              Android.
            </Typography>
            <Typography color="primary" css={css`
                font-weight: 600;
              `}>
              As of September 2024, MobileUI has reached end-of-life.
            </Typography>
            <Grid
              container
              justify={matchUpMd ? "flex-start" : "center"}
              spacing={2}
            >
              <Grid item>
                <Button
                  to={"/blog/end-of-life/"}
                  variant={"contained"}
                  size={"large"}
                  color={"primary"}
                  component={Link}
                >
                  End of Life - Good Bye! ✌️
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <GatsbyImage
            css={heroImageStyle}
            fluid={sources}
            objectFit={matchUpSm ? "contain" : "scale-down"}
            // objectPosition={showFullImage ? "left center" : "right center"}
            imgStyle={
              {
                // objectFit: "contain",
                // objectPosition: `${
                //   showFullImage ? "left center" : "right center"
                // }`,
              }
            }
            style={{
              //   // paddingBottom: `${showFullImage ? "50%" : "100%"}`,
              maxHeight: matchUpMd ? "700px" : "700px",
              //   // width: "100%"
            }}
          />
        </Grid>
      </Grid>
    </Section>
  )
}

Hero.propTypes = {}

export default Hero
